.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  position: "absolute";
  background-color: "black";
  opacity: 0.5;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 300px;
  max-width: 300px;
  background: #354052;
  color: #adb5bd;
  transition: all 2s;
}

.sidebar-wrapper{
  width: 220px;
}
.sidebar-header {
  background: #e8ecef;
  color: #adb5bd;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}


.nav-item a{
  /*color: #ced4da;*/
  color: #979aa1;
  font-weight: 300;
}

.nav-item.active {
  background: #ccc !important;
}
.nav-item.active a{
  color: #38455b !important;
}

.nav-item a:hover {
  color: #313b4c;
  transition: .2s;
}
.nav-item:hover {
  background: #f8f9fa;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
