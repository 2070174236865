#grad1 {
    height: 200px;
    background-color: red;
    /* For browsers that do not support gradients */
    background-image: linear-gradient(to bottom right, red, yellow);
}

#myContainer {
    margin-top: 63px;
    /* background-color: #FFF; */
    /* background-color: #f8f9fa; */
    /* width: 100%; */
    /* height: 100%; */
}

.headerShadow {
    box-shadow: 0px 3px 3px rgb(0 0 0 / 0.1);
}
/* .linknav{
    color:brown;
}
.linknav:hover{
    background-color: black;
    color: white;
}
.linknav:visited{
    background-color: rgb(201, 17, 17);
    color: white;
} */