.pagination {
  
}
.page-item {
  
}
.page-item .page-link {
  color: #000;
}
.page-item a.page-link {
}
.page-item.active .page-link {
  background-color: gray;
  border-color: #d9d9d9;
}
.page-item.disabled .page-link {
}
.sr-only {
}
